import TopBanner from 'components/molecules/TopBanner';

export default function TopBannerSection() {
  return (
    <TopBanner text={(
      <p>
        Learn Korean for HALF THE PRICE!
        <br className="mo" />
        {' '}
        <strong>50% OFF</strong>
        {' '}
        Yearly Subscription
      </p>
      )}
    />
  );
}
